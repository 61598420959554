// ar.js
const arTranslations = {
    welcome: 'مرحبًا',
    header1:'الرئيسية',
    header2:'الكورس',
    header3:'الخدمات',
    header4:'تسجيل الدخول',

    hero1:'كل مايخص ادارة المخزون',
    hero2:'لوكالو يقدم كل ما يتعلق بإدارة المطاعم والكافيهات ، من الدورات إلى تقديم كافة الحلول الموجودة في السوق لمساعدتك في إدارة مشروعك.',
    hero3:'اكتشف دورتنا',
    hero4:'اكتشف خدماتنا',
header10:'التسجيل',

    home1:'تعلم إدارة المطاعم والكافيهات بشكل افضل.',
    home2:'مادة تعليمية مقسمة ل ٧ أجزاء لإدارة مخزون مطعمك بشكل افضل',
    home3:'عن الدورة',
    home4:'من حضر هذه الدورة ؟',
    home5:'مقدم الدورة',
    home6:'تعلم فن إدارة المطعم في قطاع عالى الهدر شديد المنافسة',
    home7:'معرفة تكاليف المنتجات وحساب مواد المخزون الأولية تعتبر من العناصر الرئيسة لنجاح مطعمك. امتلاكك للمعرفة والعلم المناسب لمعرفة تأثيرها على الأرباح يعد أمرًا أساسيًا للنجاح في هذا قطاع شديد المنافسة .',
    more:'تفاصيل اكثر',
    home8:'حضر هذه الدورة موظفين من هذه الشركات',
    home9:'ساعدت دوراتنا التدريبية في تطوير العديد من الموظفين في قطاع المطاعم والكافيهات',
    home10:'مقدم الدورة',
    home11:'أ.محمد الشريف',
    home12:'تصفح حلولنا',
    serTitle:'نقدم كل الخدمات التي تحتاجها لادارة مخزون مطعمك او الكافي الخاص بك بنجاح ، بالاضافة الى عرض افضل الحلول لكل فئة',
    home13:'تصفح كل الفئات',

    home14:"ابق على اطلاع بما نقوم به",
    home15:'أدخل عنوان بريدك الالكتروني',
    home16:'إرسال',

    cou1:'تفاصيل الدورة',
    cou2:'قطاع',
    cou3:'الكافيهات والمطاعم',

    cou4:'المستوى',
    cou5:'كل المستويات',
    cou6:'مدة الدورة',
    cou7:'01:30:00',
    cou8:'شراء الدورة',
    cou9:'تفاصيل الدورة',
    cou10:'مواضيع الدورة',
    cou11:'وسائل الدفع المقبولة',
    cou12:'الاسم',
    cou13:'البريد الالكتروني',
    cou14:'رقم الجوال',
    cou15:'الرجاء ادخال الاسم',
    cou16:'سوف يتم ارسال بيانات تسجيل الدخول فيه',
    cou17:'الرجاء ادخال رقم الجوال',
    cou18:'شراء الان',
    // ... other Arabic translations


    aboutCourse: 'عن الدورة',
    importantElements: 'من أهم العناصر الموجودة في أي مطعم وتعد العصب الرئيسي لنجاح المطاعم والكافيهات هي معرفة تكاليف وحركة مواد المخزون للمنتجات. من خلال عملي على تطوير العديد من المطاعم والكافيهات ومقابلتي للعديد من رواد الاعمال في هذا المجال ، عملية إدارة المخزون لاتنال الاهتمام الكافي وخاصة عمليات الهدر الحاصلة في المطعم.',
    courseTopics: 'نتكلم في هذا الكورس عن',
    courseTopicsList: [
      'فهم نسبة تكلفة المواد الغذائية وتأثيرها على الأرباح.',
      'عمليات الهدر المختلفة في عملية إدارة المخزون مع أمثلة.',
      'تقدير حجم المشتريات.',
      'هندسة قائمة الطعام او ال Menu Engineering وتصنيفاتها الاربعة وكيفية والاستفادة منها.',
      ' معرفة تكلفة المنتجات واذا تم استهلاكها بشكل كامل و صحيح.',
      'عمل حملات دعائية وتسويقية معتمدة على بيانات المبيعات.',
      'صعوبة الحفاظ على هامش ربح صحي ومتزن بدون مراقبة دقيقة لاستهلاك المواد الغذائية داخل المطعم/الكوفي شوب .',
      'معرفة تكلفة ومستويات المنتجات واذا تم استهلاكها بشكل كامل و صحيح.',
    ],
    requirements: 'المتطلبات لاكمال الدورة',
    requirementsDetails: 'الحضور الذهني الكامل، لايلزم اي خبرة أو معلومات سابقة. يمكن اكمال الكورس في الوقت والمدة المناسبة لك (الدورة مسجلة)',
    forWhom: 'لمن هذا الدورة',
    forWhomList: [
      'ملاك المطاعم والكافيهات، الصغيرة والمتوسطة الحجم.',
      'للمدراء والموظفين في قطاع المطاعم والكافيهات.',
      'الموظفين في شركات التقنية التي تخص قطاع المطاعم والكافيهات.',
      ' الاشخاص المهتمين في دخول مجال إدارة المطاعم والكافيهات.',
    ],


    
      pages: 'الصفحات',
      home: 'الرئيسية',
      about: 'الخدمات',
      contact: 'كورس ادارة المخزون',
      services: 'الخدمات',
      courses: 'كورسات اون لاين',
      consultations: 'الخدمات والحلول',
      joinUs: 'انضم إلينا',
      createAccount: 'إنشاء حساب',
      login: 'تسجيل الدخول',
      languageSelection: 'اختيار اللغة',
      rightsReserved: 'كل الحقوق محفوظة © لوكالو',

      ser: 'الخدمات',
    cat: 'كل الفئات',
    all: 'الكل',
    ksa: 'السعودية',
    uae: 'الامارات',
    kw: 'الكويت',
    browse:'تصفح جميع الحلول',

    top:'أفضل الخدمات',
    det:'تفاصيل اكثر',
    visitSite:'زيارة الموقع',
    table:"حلول برامج الولاء ومميزاتها",
    table2:"حلول المحاسبية ومميزاتها",
    how:"ماهو تقييمك للحل او الشركة ؟",
    form1:"انقر هنا لتحديد إيصال الشراء",
    form2:'اكتب رايك في الخدمة ...',
    form3:'ارسال',

    mo1:'اختر الشركات',
    mo2:'اختر او الغي اختيار الشركة بالضغط على صورة الشركة',
    mo3:'الشركات المختارة',
    CloseModal:'إغلاق',

    courseName:'دورة إدارة المخزون للمطاعم والكافيهات للمبتدئين',
    couponInfo:'هل لديك كود خصم',

    sar:'ر.س',
    apply:'تطبيق',

    "paymentSuccess": {
      "title": "تم الدفع بنجاح!",
      "message": "شكرًا لدفعك. تمت عملية الدفع بنجاح.",
      "goToHome": "الذهاب إلى الصفحة الرئيسية",
      "goToCourse": "الانتقال إلى الدورة"
    },

    watchNow:'مشاهدة الدورة',

    myAccount:'حسابي',
    logout:'تسجيل الخروج',

    authModal1:'تسجيل الدخول',
    authModal2:'البريد الالكتروني',
    authModal3:'المتابعة',

    authModal5:'ادخل بريدك الالكتروني',
    authModal6:'تم ارسال رمز التحقق الى بريدك الالكتروني',
    authModal7:'ادخل رمز التحقق',

    regModal1:'التسجيل',
    regModal2:'الاسم',
    regModal3:'ادخل الاسم',
    regModal4:'البريد الالكتروني',
    regModal5:'ادخل البريد الالكتروني',
    regModal6:'رقم الجوال',
    regModal7:'ادخل رقم الجوال',

    metaTitle:'لوكالو : كل ما يخص ادارة مخزون المطاعم والكافيهات',
    metaDes:'لوكالو يقدم كل ما يتعلق بإدارة المطاعم والكافيهات ، من الدورات إلى تقديم كافة الحلول الموجودة في السوق لمساعدتك في إدارة مشروعك.',

    courseTitle:'لوكالو : دورة إدارة المخزون للمطاعم والكافيهات للمبتدئين',
    
    servicesTitle:'لوكالو - الخدمات - فرونت اوف هاوس ، باك اوف هاوس والمزيد',
    all:'الكل',
    lowestPrice:'الاقل سعراً',
    highestRated:'الاعلي تقيماً',
    local:'محلي',
    global:'عالمي',
    front:'فرونت اوف هاوس',
    moreAbout:'تفاصيل اكثر وشركات الفئة'
  };
  
  export default arTranslations;
  